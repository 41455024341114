import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.png";
import back from "../assets/images/back.png";
const Header = ({ requiredHeight }) => {
  const [navHeight, setnavHeight] = useState(0);

  useEffect(() => {
    if (navHeight < requiredHeight) {
      setTimeout(() => {
        setnavHeight(navHeight + 1);
      }, 0.5);
    }
  }, [navHeight]);

  useEffect(() => {
    setnavHeight(50);
  }, [requiredHeight]);
  return (
    <nav
      id="navbar"
      className={requiredHeight > 60?"navbar sticky-top":"navbar sticky-top shadow"}
      style={
        requiredHeight > 60
          ? {
              backgroundColor: "white",
              height: navHeight,
              backgroundImage: `url(${back})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
            }
          : { backgroundColor: "white", height: navHeight }
      }
    >
      <div className="container-fluid d-flex justify-content-center">
        <span className="navbar-brand">
          <img
            id="logo"
            src={logo}
            height="30"
            className="d-inline-block align-text-top"
          />
          <i style={{ fontSize: 12 }}>Lite</i>
        </span>
      </div>
    </nav>
  );
};

export default Header;
