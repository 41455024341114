import React, { useEffect, useState } from "react";
import FormField from "./FormField";
import CheckColor from "./CheckColor";
import PhotoHolder from "./PhotoHolder";
import EventHeader from "./EventHeader";
import YesNoOrField from "./YesNoOrField";
import Dropdown from "./Dropdown";

const Events = ({ index }) => {
  return (
    <div
      id={"event" + index}
      className="shadow"
      style={{
        marginTop: 10,
        marginBottom: 40,
        borderRadius: 10,
        padding: 10,
        overflow: "hidden",
      }}
    >
      <EventHeader index={index} />

      <div id={"eventholder" + index}>
        <FormField
          label={"Gefährdung"}
          id={"gefahrdung" + index}
          type={"text"}
        />
        <FormField
          label={"Festgestellter Mangel"}
          id={"festgestellter_mangel" + index}
          type={"text"}
        />

        <YesNoOrField index={index} />
        <FormField label={"Ort"} id={"ort" + index} type={"text"} />
        <FormField
          label={"Verursacht durch"}
          id={"verursachtdurch" + index}
          type={"text"}
        />
        <FormField label={"Gewerk"} id={"gewerk" + index} type={"text"} />
        <FormField
          label={"Schutzmaßnahme"}
          id={"schutzmassnahme" + index}
          type={"text"}
        />
        <CheckColor index={index} />
        <div
          className="card"
          style={{
            marginTop: 10,
            padding: 10,
          }}
        >
          <PhotoHolder index={index} />
        </div>
      </div>
    </div>
  );
};

export default Events;
